.container {
  padding: 20px 10px 20px 100px;
  border-bottom: 1px solid #dfe0e4;
  font-size: 14px;
}
.title {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 5px;
}
.optionsContainer {
  display: inline-block;
}
.optionsContainer .optionsButton {
  vertical-align: middle;
  margin-top: 2px;
  margin-left: 4px;
  font-size: 28px;
  color: #cccccc;
  cursor: pointer;
  line-height: 0px;
  display: inline-block;
  height: 20px;
  user-select: none;
}
.optionsContainer .optionsPopup {
  position: absolute;
  width: max-content;
  font-family: 'Open Sans Regular';
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  background-color: #fff;
  z-index: 99;
  top: 18px;
  left: 4px;
}
.optionsContainer .optionsPopup .optionAction {
  display: block;
  cursor: pointer;
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
.optionsContainer .optionsPopup .optionAction:first-child {
  padding-top: 4px;
}
.optionsContainer .optionsPopup .optionAction:last-child {
  padding-bottom: 4px;
}
.optionsContainer .optionsPopup .optionAction:hover {
  opacity: 0.7;
}
.writeSummaryButton {
  font-size: 13px;
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  user-select: none;
}
.writeSummaryButton.writeSummaryButtonDisabled {
  filter: grayscale(0.8);
  opacity: 0.5;
  cursor: default;
}
.writeSummaryButton:hover * {
  opacity: 0.7;
}
.writeSummaryButton.writeSummaryButtonDisabled:hover * {
  opacity: 1;
}
.writeSummaryButton .writeSummaryButtonAddIcon {
  width: 10px;
  height: 10px;
}
.writeSummaryButton .writeSummaryButtonText {
  display: inline-block;
  padding-left: 8px;
}
